/* eslint-disable class-methods-use-this */
import {
  tactileoTypes,
  activityItemEvaluationMethod,
} from 'utils/activityItem.util';
import { ActivityTemplateModel } from 'core/models/activityTemplate.model';
import { GroupQuestionModel } from 'core/models/elements/questions/groupQuestion.model';
import { GroupQuestionAnswerModel } from 'core/models/elements/answers/groupQuestionAnswer.model';
import { ExplodedTextQuestionModel } from 'core/models/elements/questions/explodedTextQuestion.model';
import { ExplodedTextQuestionAnswerModel } from 'core/models/elements/answers/explodedTextQuestionAnswer.model';
import { FreeTextQuestionModel } from 'core/models/elements/questions/freeTextQuestion.model';
import { FreeTextQuestionAnswerModel } from 'core/models/elements/answers/freeTextQuestionAnswer.model';
import { SortingQuestionModel } from 'core/models/elements/questions/sortingQuestion.model';
import { SortingQuestionAnswerModel } from 'core/models/elements/answers/sortingQuestionAnswer.model';
import { HoleQuestionModel } from 'core/models/elements/questions/holeQuestion.model';
import { HoleQuestionAnswerModel } from 'core/models/elements/answers/holeQuestionAnswer.model';
import { AudioQuestionModel } from 'core/models/elements/questions/audioQuestion.model';
import { AudioQuestionAnswerModel } from 'core/models/elements/answers/audioQuestionAnswer.model';
import { MultipleChoicesQuestionModel } from 'core/models/elements/questions/multipleChoicesQuestion.model';
import { MultipleChoicesQuestionAnswerModel } from 'core/models/elements/answers/multipleChoicesQuestionAnswer.model';
import { AssociateQuestionModel } from './elements/questions/associateQuestion.model';
import { AssociateQuestionAnswerModel } from './elements/answers/associateQuestionAnswer.model';
import { MapQuestionModel } from 'core/models/elements/questions/mapQuestion.model';
import { MapQuestionAnswerModel } from 'core/models/elements/answers/mapQuestionAnswer.model';
import { HotspotQuestionModel } from './elements/questions/hotspotQuestion.model';
import { HotspotQuestionAnswerModel } from './elements/answers/hotspotQuestionAnswer.model';
import { ScormQuestionModel } from 'core/models/elements/questions/scormQuestion.model';
import { ScormQuestionAnswerModel } from 'core/models/elements/answers/scormQuestionAnswer.model';
import { H5PQuestionModel } from 'core/models/elements/questions/h5pQuestion.model';
import { H5PQuestionAnswerModel } from 'core/models/elements/answers/h5pQuestionAnswer.model';
import { LtiQuestionModel } from 'core/models/elements/questions/ltiQuestion.model';
import { LtiQuestionAnswerModel } from 'core/models/elements/answers/ltiQuestionAnswer.model';
import { PdfPageContentModel } from './elements/questions/pdfPageContent.model';
import { VideoPageContentModel } from './elements/questions/videoPageContent.model';
import { InternetPageContentModel } from './elements/questions/internetPageContent.model';
import { RichTextPageContentModel } from './elements/questions/richTextPageContent.model';
import { PollQuestionModel } from './elements/questions/pollQuestion.model';
import { PollQuestionAnswerModel } from './elements/answers/pollQuestionAnswer.model';
import { CraftImageQuestionModel } from './elements/questions/craftImageQuestion.model';
import { CraftImageQuestionAnswerModel } from './elements/answers/craftImageQuestionAnswer.model';
import { GrainContentModel } from './elements/questions/grainContent.model';

/**
 * @class Defines an activity
 */
export class ActivityModel {
  /**
   * @constructor Creates an instance of an activity built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    this.title = bo?.title;
    this.items = bo?.items?.map((x) =>
      this.#convertBasicObjectFromAPI(x.item, x.answer),
    );
    this.initialItem = bo?.initial_item
      ? this.#convertBasicObjectFromAPI(bo.initial_item).item
      : null;
    this.template = bo?.template
      ? new ActivityTemplateModel(bo.template)
      : null;
    this.navigationMode = bo?.navigation_mode;
    this.displayMenu = bo?.display_menu;
    this.timeLimit = bo?.quiz_time_limit ? bo.quiz_time_limit : null;
    this.itemsTimeLimit = bo?.question_time_limit
      ? bo.question_time_limit
      : null;
    this.itemsDisplayCorrectionMode = bo?.show_result_mode_question;
    this.externalLinks = bo?.external_links?.map((x) => ({
      title: x.LinkText,
      url: x.ResourceUrl,
    }));
    this.displayCorrectionMode = bo?.show_result_mode_quiz;
    this.isAnswersValidationRequired = bo?.answers_validation_required;
    this.continueAfter = bo?.skip_question_after_nb_errors;
    this.isAnswersRequired = bo?.answers_required;
    this.legacyPlayerMode = bo?.legacy_player_mode;
    this.showResultDisplayModeQuiz = bo?.show_result_display_mode_quiz;
    this.numberCorrectAnswersInRowRequired =
      bo?.validate_quiz_after_number_right_answers;
    this.licence = bo?.licence;
  }

  /**
   * Converts the original question and answer from API to the associate model.
   * @param {boItem} boItem is the original question comes from API.
   * @param {boAnswer} boAnswer is the original answer comes from API.
   * @returns The model question associate at basic object
   */
  #convertBasicObjectFromAPI = (boItem, boAnswer) => {
    const result = { item: null, answer: null };

    switch (boItem.item_type) {
      case tactileoTypes.GROUP_QUESTION:
        result.item = new GroupQuestionModel(boItem);
        result.answer = boAnswer
          ? GroupQuestionAnswerModel.instantiateFromApi({
              ...boAnswer,
              ids: result.item.groups.map(({ id }) => id),
            })
          : null;
        break;
      case tactileoTypes.EXPLODED_TEXT_QUESTION:
        result.item = new ExplodedTextQuestionModel(boItem);
        result.answer = boAnswer
          ? ExplodedTextQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.FREE_TEXT_QUESTION:
        result.item = new FreeTextQuestionModel(boItem);
        result.answer = boAnswer
          ? FreeTextQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.H5P_QUESTION:
        result.item = new H5PQuestionModel(boItem);
        result.answer = boAnswer
          ? H5PQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.SCORM_QUESTION:
        result.item = new ScormQuestionModel(boItem);
        result.answer = boAnswer
          ? ScormQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.LTI_QUESTION:
        result.item = new LtiQuestionModel(boItem);
        result.answer = boAnswer
          ? LtiQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.SORTING_QUESTION:
        result.item = new SortingQuestionModel(boItem);
        result.answer = boAnswer
          ? SortingQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.HOLE_QUESTION:
        result.item = new HoleQuestionModel(boItem);
        result.answer = boAnswer
          ? HoleQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.AUDIO_QUESTION:
        result.item = new AudioQuestionModel(boItem);
        result.answer = boAnswer
          ? AudioQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.MULTIPLE_CHOICES_QUESTION:
        result.item = new MultipleChoicesQuestionModel(boItem);
        result.answer = boAnswer
          ? MultipleChoicesQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.POLL_QUESTION:
        result.item = new PollQuestionModel(boItem);
        result.answer = boAnswer
          ? PollQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.ASSOCIATE_QUESTION:
        result.item = new AssociateQuestionModel(boItem);
        result.answer = boAnswer
          ? AssociateQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.MAP_QUESTION:
        result.item = new MapQuestionModel(boItem);
        result.answer = boAnswer
          ? MapQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.HOTSPOT_QUESTION:
        result.item = new HotspotQuestionModel(boItem);
        result.answer = boAnswer
          ? HotspotQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.PDF_PAGE_CONTENT:
        result.item = new PdfPageContentModel(boItem);
        result.answer = boAnswer;
        break;
      case tactileoTypes.VIDEO_PAGE_CONTENT:
        result.item = new VideoPageContentModel(boItem);
        result.answer = boAnswer;
        break;
      case tactileoTypes.INTERNET_PAGE_CONTENT:
        result.item = new InternetPageContentModel(boItem);
        result.answer = boAnswer;
        break;
      case tactileoTypes.RICH_TEXT_CONTENT:
        result.item = new RichTextPageContentModel(boItem);
        result.answer = boAnswer;
        break;
      case tactileoTypes.CRAFT_IMAGE_QUESTION:
        result.item = new CraftImageQuestionModel(boItem);
        result.answer = boAnswer
          ? CraftImageQuestionAnswerModel.instantiateFromApi(boAnswer)
          : null;
        break;
      case tactileoTypes.GRAIN_CONTENT:
        result.item = new GrainContentModel(boItem);
        result.answer = boAnswer
          ? GrainContentModel.instantiateFromApi(boAnswer)
          : null;
        break;
      default:
        result.item = boItem;
        result.answer = boAnswer;
        break;
    }

    result.item.isAutoEvaluative = this.isItemAutoEvaluative(boItem);

    return result;
  };

  isItemAutoEvaluative = (item) =>
    item.evaluation_method === activityItemEvaluationMethod.AUTO;
}
